define("@woody-lite/core-session/resources/session-enax", ["exports", "@woody-lite/core-network/resources/enax", "@woody-lite/core-session/decorators/session", "@ember/service", "ember-utils/utils/strings/ucfirst", "@ember/object", "@ember/array", "@ember/utils", "@woody-lite/core-session/decorators/headers-bbva"], function (_exports, _enax, _session, _service, _ucfirst, _object, _array, _utils, _headersBbva) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIGNATURE_TYPES = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  /* eslint-disable no-magic-numbers */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HTTP_CONFLICT = 409;
  const HTTP_BAD_REQUEST = 400;
  const HTTP_SERVICE_UNAVAILABLE = 503;
  const TEMP_INTERRUPTED_SERVICE_ERROR = 'tempInterruptedService';
  const SIGNATURE_TYPES = _exports.SIGNATURE_TYPES = {
    SMS: '171',
    VOICE: '35',
    PINPOSITIONS: '171'
  };
  const SIGNATURE_PRIORITIES = [SIGNATURE_TYPES.SMS, SIGNATURE_TYPES.VOICE, SIGNATURE_TYPES.PINPOSITIONS];
  const SIGNATURE_MODALS = {
    [SIGNATURE_TYPES.SMS]: 'otpSmsModal',
    [SIGNATURE_TYPES.VOICE]: 'otpVoiceModal'
  };
  function humanize(str) {
    return (0, _ucfirst.default)(str.toLowerCase());
  }
  function selectKnownResponsePair(responsePairs) {
    if (responsePairs.length > 1) {
      for (const type of SIGNATURE_PRIORITIES) {
        const pair = responsePairs.findBy('type', type);
        if (pair) {
          return pair;
        }
      }
    }
    return responsePairs[0];
  }
  function getAuthenticationHeaders(headers) {
    const authenticationType = headers.get('authenticationtype');
    const authenticationData = headers.get('authenticationdata');
    const authenticationState = headers.get('authenticationstate');
    const authenticationChallenge = headers.get('authenticationchallenge');
    return {
      authenticationType,
      authenticationData,
      authenticationState,
      authenticationChallenge
    };
  }
  function getResponsePairs(responseTypes, responseData) {
    const knownTypes = Object.values(SIGNATURE_TYPES);
    const responsePairs = responseTypes.map((type, index) => ({
      type,
      data: responseData[index]
    }));
    const knownResponsePairs = responsePairs.filter(pair => knownTypes.includes(pair.type));
    return (0, _array.A)(knownResponsePairs);
  }
  function updateKnownResponsePairs(knownResponsePairs, responsePairs) {
    responsePairs.forEach(_ref => {
      let {
        type,
        data
      } = _ref;
      const knownPair = knownResponsePairs.findBy('type', type);
      knownPair.data = data;
    });
  }
  function buildHeaders(headers, type, values) {
    const {
      state,
      challenge,
      data,
      username
    } = values;
    headers.authenticationtype = type;
    if (data === 'usuario-cliente') {
      headers.authenticationdata = "".concat(data, "=").concat(username);
    } else {
      headers.authenticationdata = data;
    }
    if (state) {
      headers.authenticationstate = state;
    }
    if (challenge) {
      headers.authenticationchallenge = challenge;
    }
  }
  let SessionEnax = _exports.default = (_dec = (0, _headersBbva.default)('enax'), _dec2 = (0, _session.default)('client'), _dec(_class = (_class2 = class SessionEnax extends _enax.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      _initializerDefineProperty(this, "slinky", _descriptor5, this);
      _initializerDefineProperty(this, "currentSession", _descriptor6, this);
      _defineProperty(this, "Enax", (0, _object.get)(this, 'enax'));
      _defineProperty(this, "headers", this.headers || {});
      _defineProperty(this, "otpSmsModal", 'sms');
      _defineProperty(this, "otpVoiceModal", 'voice');
      _defineProperty(this, "otpPinPositionModal", 'pinpositions');
    }
    async before() {
      const {
        currentSession
      } = this;
      if (!currentSession) {
        // TODO: type this error
        throw new Error('Unable to find a valid session for app');
      }
      this.headers.tsec = (0, _object.get)(currentSession, 'tsec');
      this.headers.ContactId = (0, _object.get)(currentSession, 'contactId');
      this.headers['x-cau'] = (0, _object.get)(currentSession, 'akamaiEnding');
      return super.before(...arguments);
    }
    async done() {
      const agent = await super.done(...arguments);
      const {
        response: {
          headers
        }
      } = agent;
      const tsec = headers.get('tsec');
      const {
        currentSession
      } = this;
      if (currentSession && (0, _object.get)(currentSession, 'tsec') !== tsec && !agent.cached) {
        (0, _object.set)(currentSession, 'tsec', tsec);
        currentSession.sync();
      }
      return agent;
    }
    shouldShowAkamaiModal(response) {
      var _response$data;
      // If error status code === 503 and error-code === tempInterruptedService, always show modal.
      return response.status === HTTP_SERVICE_UNAVAILABLE && (response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data['error-code']) === TEMP_INTERRUPTED_SERVICE_ERROR;
    }
    async fail() {
      try {
        return await super.fail(...arguments);
      } catch (response) {
        if (this.shouldShowAkamaiModal(response)) {
          await this.provider.push('session', 'serviceUnavailable');
        }
        const data = response.data;

        // Handle only network errors.
        if (data) {
          var _handled, _handled2;
          let handled;
          if (data.messages) {
            handled = await this.handleGlobalFail(response);
          } else {
            handled = await this.handleLocalFail(response);
          }
          if ((_handled = handled) !== null && _handled !== void 0 && _handled.promise) {
            return handled.promise;
          }
          if ((_handled2 = handled) !== null && _handled2 !== void 0 && _handled2.alert) {
            response.alert = handled.alert;
          }
        }

        // Default alert.
        if (!response.alert) {
          response.alert = 'error.serviceTemporarilyUnavailable';
        }

        // Translate alert if exists.
        if (this.intl.exists(response.alert)) {
          response.alert = this.intl.t(response.alert);
        }
        throw response;
      }
    }
    async handleGlobalFail(response) {
      const data = response.data;
      const {
        code,
        message
      } = data.messages[0];
      const {
        currentSession
      } = this;
      const requestAuthData = (0, _object.get)(this, 'headers.authenticationdata') || response.headers.get('authenticationdata');

      // If error status code === 400, always show error message.
      if (response.status === HTTP_BAD_REQUEST && message) {
        return {
          alert: humanize(message)
        };
      }
      switch (code) {
        // 'badRequest' NO TSEC
        // La aplicación consumidora debe conseguir un TSEC válido y adjuntarlo a la petición de uso.
        case 'badRequest':
          // NO PASSWORD
          if (!requestAuthData || !requestAuthData.match(/password/)) {
            await (currentSession === null || currentSession === void 0 ? void 0 : currentSession.logout());
          }
          break;
        // 'unauthorized' TSEC INVALIDO
        // La aplicación debe repetir la llamada al servicio enviando un TSEC válido.
        // Si no dispone de uno, debe conseguirlo mediante un proceso de autenticación.
        // 'unauthorized' TSEC CADUCADO
        // Es necesario que la aplicación consiga un TSEC válido mediante
        // un proceso de autenticación.
        // 'unauthorized' TSEC ANULADO
        // Es necesario que la aplicación consiga un TSEC válido mediante
        // un proceso de autenticación
        case 'unauthorized':
          await (currentSession === null || currentSession === void 0 ? void 0 : currentSession.logout());
          break;
        default:
        // NOOP.
      }
      return null;
    }

    // eslint-disable-next-line complexity
    async handleLocalFail(response) {
      const data = response.data;
      const errorCode = data['error-code'];
      const errorMessage = data['error-message'];
      const {
        currentSession
      } = this;
      const numErrorCode = parseInt(errorCode, 10);
      const {
        authenticationType,
        authenticationData
      } = getAuthenticationHeaders(response.headers);

      // If error status code === 409, always show error message.
      if (response.status === HTTP_CONFLICT && errorMessage) {
        return {
          alert: humanize(errorMessage)
        };
      }
      if (authenticationData === 'pinpositions' && authenticationType === SIGNATURE_TYPES.PINPOSITIONS) {
        return {
          promise: this.openLevelChange(response)
        };
      }
      // ENAX error control.
      // DOC: https://docs.google.com/a/bbva.com/file/d/0B772gIBcnNCbY1V1bU1PNzJNLVk/edit
      // Error-code is a string!
      switch (numErrorCode) {
        // 50 NO TSEC
        // La aplicación consumidora debe conseguir un TSEC válido y adjuntarlo a la petición de uso.
        // 51 NO SECURITYDTO
        // La aplicación consumidora debe repetir el proceso de autenticación
        // adjuntando los datos necesarios para el mismo en la estructura SecurityDTO.
        // 61 TSEC INVALIDO
        // La aplicación debe repetir la llamada al servicio enviando un TSEC válido.
        // Si no dispone de uno, debe conseguirlo mediante un proceso de autenticación.
        // 68 TSEC CADUCADO
        // Es necesario que la aplicación consiga un TSEC válido mediante
        // un proceso de autenticación.
        // 69 TSEC ANULADO
        // Es necesario que la aplicación consiga un TSEC válido mediante
        // un proceso de autenticación
        // 70 NO AUTORIZADO
        // Es necesario ponerse en contacto con la administración de la arquitectura
        // para iniciar un proceso administrativo de autorización de uso el servicio.
        // También salta en el primer paso de una firma de operaciones, en cuyo caso
        // vendrá acompañado de los tipos de firma permitidos.
        // 79
        // Los permisos necesarios para consumir el servicio han caducado
        case 50:
        case 51:
        case 61:
        case 68:
        case 69:
        case 70:
          if (authenticationType) {
            return {
              promise: this.openLevelChange(response)
            };
          }
          await (currentSession === null || currentSession === void 0 ? void 0 : currentSession.logout());
          break;
        case 999:
          return {
            promise: this.openLevelChange(response)
          };
        case 168:
          return {
            alert: 'error.wrongOtp'
          };
        case 79:
          await (currentSession === null || currentSession === void 0 ? void 0 : currentSession.logout());
          break;
        default:
        // NOOP.
      }
      return null;
    }

    // eslint-disable-next-line max-statements
    openLevelChange(response) {
      const {
        authenticationType,
        authenticationData,
        authenticationState,
        authenticationChallenge
      } = getAuthenticationHeaders(response.headers);
      if (!authenticationType) {
        throw new Error('You have not enough authorization to fulfill this request with the current security token.');
      }
      const responseTypes = authenticationType.split(';');
      const responseData = authenticationData.split(';');
      const responsePairs = getResponsePairs(responseTypes, responseData);
      let knownResponsePairs = this.knownResponsePairs;
      if (!knownResponsePairs) {
        knownResponsePairs = responsePairs;
      } else {
        updateKnownResponsePairs(knownResponsePairs, responsePairs);
      }
      this.knownResponsePairs = knownResponsePairs;
      if ((0, _utils.isEmpty)(knownResponsePairs)) {
        throw new Error("Unhandled authorizations types '".concat(responseTypes.join(', '), "'."));
      }
      const selectedPair = selectKnownResponsePair(responsePairs);
      const {
        type,
        data
      } = selectedPair;
      const values = {
        data,
        challenge: authenticationChallenge,
        state: authenticationState,
        username: this.username
      };
      buildHeaders(this.headers, type, values);
      if (type === SIGNATURE_TYPES.SMS) {
        if (data === 'usuario-cliente' || (0, _utils.isEmpty)(data)) {
          return this.retry();
        }
        if (data === 'pinpositions') {
          return this._handleLevelChangeModal(type, data, knownResponsePairs);
        }
      }
      // When data is empty means that we need to request for a data sending selected type (f.e.: sms, voice.).
      if ((0, _utils.isEmpty)(data)) {
        return this.retry();
      }
      return this._handleLevelChangeModal(type, data, knownResponsePairs);
    }
    async _handleLevelChangeModal(type, data, responsePairs) {
      const modalOptions = {
        factory: 'ENAX',
        resource: this,
        type,
        data,
        responsePairs,
        phone: this.phone || ''
      };
      let modalProperty = SIGNATURE_MODALS[type];

      // We can't put it in the signature modal because 171 it would replace on the sms modal
      if (data === 'pinpositions') {
        modalProperty = 'otpPinPositionModal';
      }
      const modalName = this[modalProperty];
      return this.modal.open(modalName, modalOptions);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "currentSession", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
});