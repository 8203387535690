define("@woody-lite/engine-savings-and-investment/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('overview', function () {
      this.route('deposit', {
        path: '/deposit/:product_id'
      });
      this.route('stock-account', {
        path: '/stock-account/:product_id'
      });
      this.route('international-fund', {
        path: '/international-fund/:product_id'
      });
    });
    this.route('deposit', {
      path: '/deposit/:product_id'
    }, function () {
      this.route('more-info');
    });
    this.route('stock-account', {
      path: '/stock-account/:product_id'
    }, function () {
      this.route('more-info');
      this.route('related-funds');
    });
    this.route('international-fund', {
      path: '/international-fund/:product_id'
    }, function () {
      this.route('more-info');
      this.route('graph-evolution-profitability');
      this.route('finder');
    });
  });
});