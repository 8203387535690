define("@woody-lite/addon-feedback/components/modal-iframe", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/core-foundation/components/modal", "@ember/object"], function (_exports, _component, _templateFactory, _modal, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type="modal-primary" as |header|>
  		<header.title @title={{t "label.termsAndConditions"}} />
  		<header.right as |i|>
  			<i.icon
  				data-id="btnClose"
  				@class="icon-close"
  				@onClick={{this.close}}
  				@accessibilityText={{t "label.close"}}
  			/>
  		</header.right>
  	</wrapper.header>
  
  	<wrapper.network />
  
  	<wrapper.content>
  		<article>
  			<IframeExternal
  				data-id="feedback-opinator-terms-and-conditions"
  				@src={{this.url}}
  				@class="vh"
  			/>
  		</article>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "HHl1y500",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.termsAndConditions\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnClose\"]],[[\"@class\",\"@onClick\",\"@accessibilityText\"],[\"icon-close\",[30,0,[\"close\"]],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\n\\t\"],[8,[30,1,[\"network\"]],null,null,null],[1,\"\\n\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,\"article\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,2],[[24,\"data-id\",\"feedback-opinator-terms-and-conditions\"]],[[\"@src\",\"@class\"],[[30,0,[\"url\"]],\"vh\"]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\"],false,[\"wrapper-main\",\"t\",\"iframe-external\"]]",
    "moduleName": "@woody-lite/addon-feedback/components/modal-iframe.hbs",
    "isStrictMode": false
  });
  let ModalIframe = _exports.default = (_class = class ModalIframe extends _modal.default {
    get url() {
      return this.model.options.src;
    }
    close() {
      return this.resolve();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalIframe);
});