define("@woody-lite/addon-crm-campaigns/models/crm-campaign", ["exports", "ember-object-data-model/models/base", "@ember/template", "@ember/object", "@ember/array", "@ember/service", "@ember/application", "ember-utils/utils/banking/format-number", "ember-utils/utils/strings/unserialize-url", "@woody-mrs-potato/addon-utils/utils/iso-converter", "@woody-lite/addon-crm-campaigns/models/enax-action", "@glimmer/tracking", "@ember/utils"], function (_exports, _base, _template, _object, _array, _service, _application, _formatNumber, _unserializeUrl, _isoConverter, _enaxAction, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CAMPAIGN_TYPE = {
    crm: 'crm',
    ocl: 'ocl'
  };
  const MOBILE_CAMPA = {
    ocl: '402',
    payrollAdvance: '400',
    experience: 'EXP'
  };
  const OFFER_TYPE_EXPERIENCE = 'EXPERIENCE';
  const MOBILE_CAMPAIGN = 'MOBILE_CAMPA';
  const TIP_CAMPAIGN = 'TIP_CAMPA';
  const AMOUNT_GROUP = 'IMPORTE';
  let CampaignModel = _exports.default = (_class = class CampaignModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "globalContext", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "_actions", _descriptor4, this);
      _initializerDefineProperty(this, "_claim", _descriptor5, this);
      _initializerDefineProperty(this, "_imageName", _descriptor6, this);
      _initializerDefineProperty(this, "_title", _descriptor7, this);
      _initializerDefineProperty(this, "_sourceName", _descriptor8, this);
      _initializerDefineProperty(this, "feedback", _descriptor9, this);
    }
    get customizeDetailsOffers() {
      var _this$content;
      return (0, _array.A)(this._customizeDetailsOffers || ((_this$content = this.content) === null || _this$content === void 0 ? void 0 : _this$content.customizeDetailsOffers));
    }
    set customizeDetailsOffers(value) {
      this._customizeDetailsOffers = (0, _array.A)(value);
    }
    get mobileCampa() {
      var _this$customizeDetail;
      return (_this$customizeDetail = this.customizeDetailsOffers) === null || _this$customizeDetail === void 0 ? void 0 : _this$customizeDetail.findBy('id', MOBILE_CAMPAIGN);
    }
    get tipCampa() {
      var _this$customizeDetail2;
      return (_this$customizeDetail2 = this.customizeDetailsOffers) === null || _this$customizeDetail2 === void 0 ? void 0 : _this$customizeDetail2.findBy('id', TIP_CAMPAIGN);
    }
    get sourceNameComputed() {
      var _this$tipCampa, _this$tipCampa$value, _this$mobileCampa;
      const isOCL = ((_this$tipCampa = this.tipCampa) === null || _this$tipCampa === void 0 ? void 0 : (_this$tipCampa$value = _this$tipCampa.value) === null || _this$tipCampa$value === void 0 ? void 0 : _this$tipCampa$value.toLowerCase()) === CAMPAIGN_TYPE.ocl || ((_this$mobileCampa = this.mobileCampa) === null || _this$mobileCampa === void 0 ? void 0 : _this$mobileCampa.value) === MOBILE_CAMPA.ocl;
      return isOCL ? CAMPAIGN_TYPE.ocl : CAMPAIGN_TYPE.crm;
    }
    get sourceName() {
      return (0, _utils.isNone)(this._sourceName) ? this.sourceNameComputed : this._sourceName;
    }
    set sourceName(value) {
      this._sourceName = value;
    }
    get isExperience() {
      var _this$mobileCampa2;
      return ((_this$mobileCampa2 = this.mobileCampa) === null || _this$mobileCampa2 === void 0 ? void 0 : _this$mobileCampa2.value) === MOBILE_CAMPA.experience;
    }
    get isExperienceCampaign() {
      return (0, _object.get)(this, 'offerType.id') === OFFER_TYPE_EXPERIENCE;
    }
    get isOcl() {
      return this.sourceName === CAMPAIGN_TYPE.ocl;
    }
    get isPayrollAdvance() {
      var _this$mobileCampa3;
      return ((_this$mobileCampa3 = this.mobileCampa) === null || _this$mobileCampa3 === void 0 ? void 0 : _this$mobileCampa3.value) === MOBILE_CAMPA.payrollAdvance;
    }
    get detailsAdvert() {
      return (0, _array.A)((0, _object.get)(this, 'details_advert'));
    }
    get detailComputed() {
      var _this$detailsAdvert;
      const locale = this.intl.primaryLocale;
      const lang = (0, _isoConverter.default)(locale);
      return (_this$detailsAdvert = this.detailsAdvert) === null || _this$detailsAdvert === void 0 ? void 0 : _this$detailsAdvert.findBy('lang', lang);
    }
    get detail() {
      return (0, _utils.isNone)(this._detail) ? this.detailComputed : this._detail;
    }
    set detail(value) {
      this._detail = value;
    }
    get image() {
      var _this$detail, _this$detail$imageUrl, _this$detail$imageUrl2;
      return (_this$detail = this.detail) === null || _this$detail === void 0 ? void 0 : (_this$detail$imageUrl = _this$detail.imageUrl) === null || _this$detail$imageUrl === void 0 ? void 0 : (_this$detail$imageUrl2 = _this$detail$imageUrl[0]) === null || _this$detail$imageUrl2 === void 0 ? void 0 : _this$detail$imageUrl2.url_image;
    }
    get imageName() {
      var _this$detail2;
      return (0, _utils.isNone)(this._imageName) ? (_this$detail2 = this.detail) === null || _this$detail2 === void 0 ? void 0 : _this$detail2.imageName : this._imageName;
    }
    set imageName(value) {
      this._imageName = value;
    }
    get iconName() {
      var _this$detail3;
      return (_this$detail3 = this.detail) === null || _this$detail3 === void 0 ? void 0 : _this$detail3.iconName;
    }
    get title() {
      var _this$detail4;
      const value = (0, _utils.isNone)(this._title) ? (_this$detail4 = this.detail) === null || _this$detail4 === void 0 ? void 0 : _this$detail4.title : this._title;
      return (0, _template.htmlSafe)(this._parseValues(this.parseHTML(value)));
    }
    set title(value) {
      this._title = value;
    }
    get pretitle() {
      var _this$detail5;
      const value = (0, _utils.isNone)(this._pretitle) ? (_this$detail5 = this.detail) === null || _this$detail5 === void 0 ? void 0 : _this$detail5.pretitle : this._pretitle;
      return (0, _template.htmlSafe)(this._parseValues(value));
    }
    set pretitle(value) {
      this._pretitle = value;
    }
    get claim() {
      var _this$detail6, _this$detail6$claim;
      return (0, _utils.isNone)(this._claim) ? (_this$detail6 = this.detail) === null || _this$detail6 === void 0 ? void 0 : (_this$detail6$claim = _this$detail6.claim) === null || _this$detail6$claim === void 0 ? void 0 : _this$detail6$claim.trim() : this._claim;
    }
    set claim(value) {
      this._claim = value;
    }
    get opinatorButtonText() {
      var _this$detail7;
      return (_this$detail7 = this.detail) === null || _this$detail7 === void 0 ? void 0 : _this$detail7.opinatorButtonText;
    }
    get opinatorTitle() {
      var _this$detail8;
      return (_this$detail8 = this.detail) === null || _this$detail8 === void 0 ? void 0 : _this$detail8.opinatorTitle;
    }
    get productId() {
      var _this$customizeDetail3, _this$customizeDetail4;
      return (_this$customizeDetail3 = this.customizeDetailsOffers) === null || _this$customizeDetail3 === void 0 ? void 0 : (_this$customizeDetail4 = _this$customizeDetail3.findBy('id', 'productId')) === null || _this$customizeDetail4 === void 0 ? void 0 : _this$customizeDetail4.value;
    }
    get amountLimit() {
      var _this$customizeDetail5, _this$customizeDetail6;
      return (_this$customizeDetail5 = this.customizeDetailsOffers) === null || _this$customizeDetail5 === void 0 ? void 0 : (_this$customizeDetail6 = _this$customizeDetail5.findBy('id', 'amountLimit')) === null || _this$customizeDetail6 === void 0 ? void 0 : _this$customizeDetail6.value;
    }
    get prioritiesAdvert() {
      return (0, _object.get)(this, 'priorities_advert.0');
    }
    get productType() {
      var _this$prioritiesAdver, _this$prioritiesAdver2;
      return (_this$prioritiesAdver = this.prioritiesAdvert) === null || _this$prioritiesAdver === void 0 ? void 0 : (_this$prioritiesAdver2 = _this$prioritiesAdver.position) === null || _this$prioritiesAdver2 === void 0 ? void 0 : _this$prioritiesAdver2.split('/')[0];
    }
    get position() {
      return this.productType;
    }
    get location() {
      var _this$prioritiesAdver3;
      return (_this$prioritiesAdver3 = this.prioritiesAdvert) === null || _this$prioritiesAdver3 === void 0 ? void 0 : _this$prioritiesAdver3.location;
    }
    get priority() {
      var _this$prioritiesAdver4;
      return (_this$prioritiesAdver4 = this.prioritiesAdvert) === null || _this$prioritiesAdver4 === void 0 ? void 0 : _this$prioritiesAdver4.priority;
    }
    get description() {
      var _this$detail9;
      const desc = ((_this$detail9 = this.detail) === null || _this$detail9 === void 0 ? void 0 : _this$detail9.description) || '';
      return (0, _template.htmlSafe)(this._parseValues(this.parseHTML(desc)));
    }
    parseHTML(string) {
      var _ref, _doc$body, _doc$body$firstChild, _doc$body2;
      const parser = new DOMParser();
      const doc = parser.parseFromString(string || '', 'text/html');
      return (_ref = (doc === null || doc === void 0 ? void 0 : (_doc$body = doc.body) === null || _doc$body === void 0 ? void 0 : (_doc$body$firstChild = _doc$body.firstChild) === null || _doc$body$firstChild === void 0 ? void 0 : _doc$body$firstChild.firstChild) || (doc === null || doc === void 0 ? void 0 : (_doc$body2 = doc.body) === null || _doc$body2 === void 0 ? void 0 : _doc$body2.firstChild)) === null || _ref === void 0 ? void 0 : _ref.nodeValue;
    }

    // Prevent setting this value doing nothing
    set description(value) {}
    get inAppScreenData() {
      var _this$detail10, _this$detail11;
      const url = (_this$detail10 = this.detail) === null || _this$detail10 === void 0 ? void 0 : _this$detail10.url_target;
      const queryParams = (_this$detail11 = this.detail) === null || _this$detail11 === void 0 ? void 0 : _this$detail11.url_queryparams;
      let inAppScreenData = Object.assign({}, (0, _object.get)(this, 'limits'));
      if (!this.isOcl) {
        const customizeDetailsOffers = this.customizeDetailsOffers;
        inAppScreenData = customizeDetailsOffers.reduce((ret, _ref2) => {
          let {
            value,
            id
          } = _ref2;
          ret[id] = value;
          return ret;
        }, {});
        if (this.isPayrollAdvance) {
          const amount = customizeDetailsOffers.findBy('id', 'IMP_CAMPA');
          if (amount) {
            inAppScreenData.amount = parseFloat(amount.value);
          }
        }
      }
      return {
        crmOfferId: (0, _object.get)(this, 'id'),
        ...inAppScreenData,
        ...(0, _unserializeUrl.default)(url),
        ...queryParams
      };
    }
    get actionsComputed() {
      var _this$detail12, _this$detail13;
      const url = (_this$detail12 = this.detail) === null || _this$detail12 === void 0 ? void 0 : _this$detail12.url_target;
      const transitionModel = (_this$detail13 = this.detail) === null || _this$detail13 === void 0 ? void 0 : _this$detail13.url_model;
      const inAppScreenData = this.inAppScreenData;
      const eventActionFactory = (0, _application.getOwner)(this).factoryFor('model:event-action');
      const enaxActionFactory = (0, _application.getOwner)(this).factoryFor('model:enax-action');
      return (0, _array.A)([eventActionFactory.create({
        url,
        inAppScreenData,
        transitionModel
      }), ...Object.values(_enaxAction.TRIGGERS).map(trigger => enaxActionFactory.create({
        campaignId: (0, _object.get)(this, 'id'),
        trigger,
        channel: (0, _object.get)(this, 'channel')
      }))]);
    }
    get actions() {
      return (0, _utils.isNone)(this._actions) ? this.actionsComputed : this._actions;
    }
    set actions(value) {
      this._actions = value;
    }
    get loadActions() {
      var _this$actions;
      return (_this$actions = this.actions) === null || _this$actions === void 0 ? void 0 : _this$actions.filter(a => (0, _object.get)(a, 'trigger') === 'load');
    }
    _parseValues() {
      let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const context = (0, _object.get)(this, 'customizeDetailsOffers').reduce((acc, a) => {
        acc[a.id] = a.value;
        return acc;
      }, {});
      const amount = (0, _object.get)(this, 'limits.maxAmount') || this.amountLimit;
      return str.replace(/\${(.+?)}/gm, (_, group) => {
        if (this.isOcl && group === AMOUNT_GROUP && amount) {
          return (0, _formatNumber.default)(amount);
        }
        return context[group] || '';
      });
    }
    doActions(name) {
      (0, _object.get)(this, name).forEach(targetAction => this.callService(targetAction));
    }
    async callService(targetAction) {
      try {
        await this.provider.push('user-events', 'sendUserEvent', targetAction.inAppScreenData.eventType, targetAction.inAppScreenData.campaignId);
      } catch {
        //
      }
      return targetAction.dismissAfterAction;
    }
    sendDisplayEvent() {
      const id = (0, _object.get)(this, 'id');
      const bubblesDisplayed = this.globalContext.bubblesDisplayed || [];
      if (!bubblesDisplayed.includes(id)) {
        this.doActions('loadActions');
        bubblesDisplayed.push(id);
        this.globalContext.bubblesDisplayed = bubblesDisplayed;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "globalContext", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_actions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_claim", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_imageName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_sourceName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "feedback", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});