define("@woody-lite/engine-my-trips/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('index-closed', {
      path: '/closed'
    });
    this.route('dashboard');
    this.route('create', function () {
      this.route('index');
      this.route('date');
    });
    this.route('modify');
    this.route('budget');
    this.route('bills');
    this.route('expense');
    this.route('associate-expense');
    this.route('expense-detail');
  });
});